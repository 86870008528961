:root {
  --dark-green: #00190D;
  --light-green: #1EC677;
  --white: #FFFFFF
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AvenirLTStd-Black";
  src: local("AvenirLTStd-Black"), url(./assests/font/AvenirLTStd-Book.otf)format('opentype')
}

body {
  margin: 0;
  position: relative;
  font-family: AvenirLTStd-Black;
}

.paginationBttns {
  display: flex;
  list-style: none;
  justify-content: center;
}

.paginationBttns li {
  display: none;
}

.paginationBttns .previous, .paginationBttns .next {
  display: block;
}


.paginationBttns .disabled a {
  background-color: #DADADA;
}

.paginationBttns a {
  padding: 3px 6px;
  margin: 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  border: 1px solid #DADADA;
}

.paginationActive a {
  background-color: #FA8151;
}


.table-wrapper {
  background-color: var(--white);
  padding: 10px 0;
  overflow-x: scroll;
}

.table-wrapper.dark-head {
  padding: 0 0 20px 0;
}

.table-wrapper::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
}

.table-wrapper table {
  border-collapse: collapse;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
}

.table-wrapper table th {
  text-align: start;
  font-weight: 700;
  color: #000;
  background-color: white;
  padding: 10px 10px;

}

.table-wrapper.dark-head table th {
  background-color: #fcfcfc;
  color: #000;
  font-weight: 500;
}

.table-wrapper.white-head table th {
  background-color: white;
  border-bottom: 1px solid grey;
  color: #000;
  font-weight: 500;
}

.table-wrapper.dark-head table th:first-child {
  padding: 15px 20px;
}

.table-wrapper table td {
  padding-top: 12px;
  text-transform: capitalize;
  /* vertical-align: text-top; */
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.239);
  border-top: 0.5px solid rgba(128, 128, 128, 0.239);
  padding: 10px 10px;
}


.table-wrapper.dark-head table td:first-child {
  padding-left: 20px;
}

@media (max-width: 80rem) {
  .table-wrapper table.longer-table {
      width: 900px;
  }
}

@media (max-width: 50rem) {
  .table-wrapper table.long-table {
      width: 700px;
  }
}

@media (max-width: 40rem) {
  .table-wrapper table {
      width: 700px;
  }

  .table-wrapper table.long-table {
      width: 700px;
  }

  .table-wrapper table.longer-table {
      width: 900px;
  }
}

@media (max-width: 30rem) {
  .table-wrapper table {
      width: 600px;
  }

  .table-wrapper table.long-table {
      width: 700px;
  }

  .table-wrapper table.longer-table {
      width: 900px;
  }
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.FormSelectMain {
  margin-bottom: 12px;
}

.FormSelectMain .subLabel {
  color: #B8B8B8;
  font-size: 12px;
}

.FormSelectMain .css-1s2u09g-control,
.FormSelectMain .css-1pahdxg-control,
.FormSelectMain .css-1insrsq-control {
  position: relative;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid grey;
  box-shadow: none;
  outline: none;
  padding: 0px 40px 0px 20px;
  margin-top: 8px;
  /* font-size: ; */
  letter-spacing: 0.04em;
}

.FormSelectMain .css-1s2u09g-control .css-1wy0on6,
.FormSelectMain .css-1pahdxg-control .css-1wy0on6,
.FormSelectMain .css-1insrsq-control .css-1wy0on6 {
  display: none;
}

.FormSelectMain .css-1pndypt-Input {
  height: 48px;
  margin: 0;
  padding: 0;
}

/* .FormSelectMain .css-1pahdxg-control:hover {
  border: 1px solid var();
  box-shadow: none;
} */

.FormSelectMain .css-1s2u09g-control::after,
.FormSelectMain .css-1pahdxg-control::after {
  content: "S";
  position: absolute;
  color: transparent;
  right: 20px;
  height: 12px;
  width: 12px;
  background-image: url("assests/icons/playDown.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.FormSelectMain .css-319lph-ValueContainer,
.FormSelectMain .css-g1d714-ValueContainer {
  padding: 0;
}

.FormSelectMain .css-6j8wv5-Input,
.FormSelectMain .css-jzldcf-Input {
  height: 50px;
  margin: 0;
  padding: 0;
}

.FormSelectMain .css-1hb7zxy-IndicatorsContainer {
  display: none;
}