/* .phone {
    border: 1px solid red;
    border-radius: 4px;
    height: 48px;
} */

.PhoneInput {
    border: 1px solid gainsboro;
    border-radius: 4px;
    height: 48px;
    padding: 4px 0px 4px 20px;
}

.PhoneInput input {
    outline: 2px solid transparent;
    outline-offset: 2px;

}